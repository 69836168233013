.container {
  composes: container from './LogosSection.module.css';
  margin: 7.5rem auto 6.25rem auto;
}

.title {
  composes: title from './LogosSection.module.css';
  font-size: 40px;
}

.sectionTitle {
  composes: sectionTitle from './LogosSection.module.css';
  line-height: 18px;
}

.logoSectionContainer {
  composes: logoSectionContainer from './LogosSection.module.css';
}

@media (max-width: 600px) {
  .partners {
    display: none;
  }
  .container {
    margin: 4rem 0 5rem 0;
  }
  .title {
    font-size: 28px;
    line-height: 30.8px;
    letter-spacing: -0.56px;
    margin-bottom: 2rem;
  }
  .logoSectionContainer {
    gap: 0;
    margin-bottom: 2rem;
  }
}
