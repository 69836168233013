.section {
  display: flex;
  flex-direction: column;
}

.background {
  background-color: #fff5f5;
  width: 100%;
}

.items {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  margin: 2rem 0;
  overflow: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.right {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.topBar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.button {
  background-color: var(--accent-color);
  color: var(--text-accent-color);
  border-radius: 0.75rem;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.09em;
  display: flex;
}

.buttonMobile {
  display: none;
}

.title {
}

@media (max-width: 768px) {
  .items {
    flex-direction: row;
    margin-left: calc(-1 * var(--container-padding-mobile));
    margin-right: calc(-1 * var(--container-padding-mobile));
    padding-left: var(--container-padding-mobile);
    padding-right: var(--container-padding-mobile);
  }

  .topBar {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .buttonDesktop {
    display: none;
  }

  .buttonMobile {
    display: flex;
  }
}
