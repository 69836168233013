.AdultModal_container__HUE5_ {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 56px 16px;

  background-color: var(--modal-overlay-color);
}

.AdultModal_hidden__Xsi9L {
  display: none;
}

.AdultModal_modal__Jy668 {
  width: 100%;
  max-width: 500px;
  background-color: var(--modal-background-color);
  padding: 36px 24px;
  text-align: center;
  display: flex;
  flex-direction: column;
  border-radius: var(--modal-border-radius);
}

.AdultModal_title__z5IpN {
  margin-bottom: 36px;
}

.AdultModal_button___cyFj {
  margin-bottom: 4px;
  justify-content: center;
}

.Select_container__Pe9g7 {
}

.Select_control__WK2UX {
  display: flex;
  align-items: center;
  gap: 0.3rem;
}

.Select_label__M45G1 {
  color: var(--select-label-color);
}

.Select_value__yicxb {
  display: flex;
  align-items: center;
  gap: 0.3rem;
  color: var(--select-value-color);
  font-weight: 700;
  background-color: unset;
  cursor: pointer;
}

.Select_content__Aofpo {
  padding: 0.4rem;
  background-color: var(--select-background-color);
  border-radius: 0.5rem;
  box-shadow: 0px 8px 8px var(--shadow-color);
}

.Select_icon__lUJs8 {
  width: 20px;
  height: 20px;
}

.Select_item__MPcqP {
  /* for some reason by default its Times New Roman and i have problem unseting it to inherit */
  font-family: var(--font-poppins);
  cursor: pointer;
  padding: 0.8rem 1rem;
  border-radius: 0.25rem;
}

.Select_item__MPcqP:hover {
  background-color: var(--select-item-background-color-hover);
}

.Select_item__MPcqP[data-state='checked'] {
  background-color: var(--select-item-background-color-selected);
  color: var(--select-item-color-selected);
}

  .ReservationOfferCard_styledTile__UhINx {
    position: relative;
    height: 100%;
    max-width: 19rem;
    width: 305px;
  }
  
  .ReservationOfferCard_styledTileWide__s9gIG {
    border-radius: 0.75rem;
    display: flex;
    align-items: flex-start;
    width: 100%;
    padding: 1.5rem;
    max-width: 28rem;
  }
  
  @media (min-width: 768px) {
    .ReservationOfferCard_styledTileWide__s9gIG {
      max-width: 45rem;
      padding: 2rem;
      border-radius: 0.5rem;
    }
  }
  
  .ReservationOfferCard_photoWrapper__1l1ml {
    height: 0;
    padding-top: calc(1 * 100%);
    overflow: hidden;
    position: relative;
    border-radius: 0.5rem;
  }
  
  .ReservationOfferCard_photoWrapperMobile__n8jfe {
    padding-top: calc(9 / 16 * 100%);
  }
  
  .ReservationOfferCard_photoWrapperDesktop__YMVP_ {
    padding-top: calc(1 * 100%);
  }
  
  .ReservationOfferCard_widePhotoWrapper__kcj8X {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9;
    max-width: 25rem;
    overflow: hidden;
    position: relative;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }
  
  .ReservationOfferCard_heading6__g6hN4 {
    font-weight: 500;
  }

  .ReservationOfferCard_image__YYzJo {
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
  }

  @media (min-width: 500px) {
    .ReservationOfferCard_widePhotoWrapper__kcj8X {
      aspect-ratio: 1 / 1;
      margin-right: 2.5rem;
      margin-bottom: 0;
      min-width: 186px;
      max-width: 100%;
      height: auto;
    }
  }
  
  .ReservationOfferCard_photo__TTLvf, .ReservationOfferCard_widePhoto__MW48M {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }
  
  .ReservationOfferCard_infoBox__XX1q_ {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    flex: 1 1;
    color: var(--gray-1000);
    width: 100%;
  }
  
  .ReservationOfferCard_infoRow__yqpu6 {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.75rem;
    gap: 1rem;
    flex: 1 1;
  }
  
  .ReservationOfferCard_marginBottomHalf__YEHJ6 {
    margin-bottom: 0.5rem;
  }
  
  .ReservationOfferCard_marginBottomThreeQuarters__YaQsL {
    margin-bottom: 0.75rem;
  }
  
  .ReservationOfferCard_detailsContainer__O5LXa {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .ReservationOfferCard_title__MgvyP, .ReservationOfferCard_infoTitle__03LjL {
    font-weight: 700;
    text-transform: uppercase;
  }
  
  .ReservationOfferCard_infoSubtitle__1sQ_w {
    font-weight: regular;
  }
  
  .ReservationOfferCard_eyebrow__gzebB {
    color: var(--gray-600);
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  .ReservationOfferCard_button__wdBPG {
    font-weight: semiBold;
  }
  
.CollectionSection_section__OU28d {
  display: flex;
  flex-direction: column;
}

.CollectionSection_background__akGVc {
  background-color: #fff5f5;
  width: 100%;
}

.CollectionSection_items__771YA {
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  margin: 2rem 0;
  overflow: scroll;
  overflow-y: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.CollectionSection_right__r_Jzv {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.CollectionSection_topBar___lsy9 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.CollectionSection_button__HpXZp {
  background-color: var(--accent-color);
  color: var(--text-accent-color);
  border-radius: 0.75rem;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.09em;
  display: flex;
}

.CollectionSection_buttonMobile__DRYdt {
  display: none;
}

.CollectionSection_title__zdtQW {
}

@media (max-width: 768px) {
  .CollectionSection_items__771YA {
    flex-direction: row;
    margin-left: calc(-1 * var(--container-padding-mobile));
    margin-right: calc(-1 * var(--container-padding-mobile));
    padding-left: var(--container-padding-mobile);
    padding-right: var(--container-padding-mobile);
  }

  .CollectionSection_topBar___lsy9 {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem;
  }

  .CollectionSection_buttonDesktop__vKvJd {
    display: none;
  }

  .CollectionSection_buttonMobile__DRYdt {
    display: flex;
  }
}

.LocationCollectionSection_wrapper__MPOE0 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.LocationCollectionSection_title__tR2xD {
}

.LocationCollectionSection_button__h8T_3 {
  background-color: var(--accent-color);
  color: var(--text-accent-color);
  border-radius: 0.75rem;
  font-size: 1rem;
  line-height: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.09em;
  display: flex;
}

.LocationCollectionSection_left__2NyeJ {
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media (max-width: 768px) {
  .LocationCollectionSection_wrapper__MPOE0 {
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;
  }

  .LocationCollectionSection_left__2NyeJ {
    align-items: flex-start;
    flex-direction: column;
  }
}
.ReservationOfferCardCZ_infoTitle___P5_u {
  font-size: 24px;
  font-weight: 400;
}

.ReservationOfferCardCZ_eyebrow__eqs1e {
  font-family: var(--font-roboto);
}
.CollectionSectionCZ_background__VzNLA {
  background-color: #FFF;
}

.CollectionSectionCZ_title__KbTyF {
  text-transform: uppercase;
}
.CollectionSectionPL_section__mtGAp {
}

.CollectionSectionPL_background__zgALD {
  background-color: #fff;
}

.CollectionSectionPL_topBar__la8qZ {
}

@media (max-width: 768px) {
  .CollectionSectionPL_topBar__la8qZ {
    gap: 2rem;
  }
  .CollectionSectionPL_section__mtGAp {
    padding-bottom: 16px;
  }
}

.PhoneSection_section__qmVvv {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
  
.PhoneSection_background__i8pgq {
  background-color: var(--section-background-color);
  width: 100%;
}

.PhoneSection_title__5cxTI {
  text-transform: none;
}

.PhoneSection_logo__ieiPa {
  width: 194px;
}

.PhoneSection_left__3Rhrj {
  display: flex;
  flex-direction: column;
  max-width: 30rem;
  justify-content: center;
  gap: 2rem;
}

.PhoneSection_buttonContainer__unWib {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}

.PhoneSection_textContainer__azOzD {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  white-space: pre-wrap;
}

.PhoneSection_right__YA_Q9 {

}

@media (max-width: 990px) {
  .PhoneSection_section__qmVvv {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .PhoneSection_right__YA_Q9 {
    align-self: center;
  }
}
.PhoneSectionRW_textContainer__ezmXQ {
  color: #fff;
}

.PhoneSectionRW_background__dRPfE {
  position: relative;
  overflow-y: hidden;
  background-image: url('/lp/assets/konfetti.png');
  background-size: 1102px 1374px;
  background-position: left 100% top -110px;
  background-repeat: no-repeat;
}

.PhoneSectionRW_section__Bpz2t {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.PhoneSectionRW_title__ujoVr {
  font-size: 40px;
}

.PhoneSectionRW_left__OoCiC {
  gap: 48px;
}

@media (max-width: 990px) {
  .PhoneSectionRW_background__dRPfE {
    min-height: 971px;
    background-size: 596px 743px;
    background-position: left 100% bottom -140%;
  }
  .PhoneSectionRW_section__Bpz2t {
    flex-direction: column;
    gap: 4rem;
  }
  .PhoneSectionRW_textContainer__ezmXQ {
    gap: 2rem;
  }
  .PhoneSectionRW_title__ujoVr {
    font-size: 28px;
    line-height: 30.8px;
  }
  .PhoneSectionRW_description__fG2us {
    font-size: 18px;
    line-height: 27px;
  }
  .PhoneSectionRW_left__OoCiC {
    gap: 2rem;
  }
}

.HeroSection_containerWrapper__FwhtJ {
  display: flex;
  background-color: var(--section-background-color);
}

.HeroSection_container__aGPb9 {
  cursor: pointer;
  overflow: hidden;
  margin: 0 auto;
  display: grid;
  grid-template-areas: 'text' 'slider' 'statistics';
  align-self: center;
  justify-content: space-between;
  height: -moz-fit-content;
  height: fit-content;
  grid-gap: 20px;
  gap: 20px;
  padding: 32px 16px;
  grid-template-columns: 1fr;
}

.HeroSection_date__puM1q {
  color: var(--accent-color);
}

.HeroSection_flexboxFirst__9WDOT,
.HeroSection_flexboxSecond__TvEpy {
  display: flex;
  flex-direction: column;
  border-radius: 24px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  flex-grow: 1;
  background-size: cover;
  background-repeat: no-repeat;
  flex-basis: auto;
}

.HeroSection_flexboxFirst__9WDOT {
  padding: 64px;
  color: var(--hero-font-color);
  grid-area: text;
  text-align: left;
  box-shadow: none;
  border: none;
  padding: 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
  background-image: url('/assets/generic/hero_background.png');
}

.HeroSection_flexboxSecond__TvEpy {
  padding: 36px;
  color: var(--hero-right-color);
  grid-area: slider;
  margin-top: 0;
  background-size: cover;
  height: auto;
  border-radius: 16px;
  overflow: hidden;
  padding: 0;
  max-width: 600px;
  aspect-ratio: 343 / 161;
  background-image: url('/assets/generic/hero_background.png');
}

.HeroSection_pricebox__bZSoG {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 0;
}

.HeroSection_title__KhsSj {
  letter-spacing: -0.03em;
  line-height: 3.25rem;
  font-size: 3rem;
  width: 70vw;
  font-weight: 700;
}

.HeroSection_description__6I3mv {
  font-size: 1.25rem;
  line-height: 1.875rem;
  letter-spacing: -0.02em;
  white-space: pre-wrap;
}

.HeroSection_mainPrice__s9d5N {
  font-size: 30px;
  font-weight: 500;
}

.HeroSection_button__vOUxw {
  width: -moz-fit-content;
  width: fit-content;
  align-items: center;
  border-radius: 12px;
  margin-top: 8px;
  margin-bottom: 24px;
  font-size: 16px;
  font-weight: 800;
  line-height: 16.5px;
  letter-spacing: 0.09em;
}

.HeroSection_extra__WlRnR {
  opacity: 0.7;
}

.HeroSection_secondaryText__9hwt4 {
}

.HeroSection_statistics__AVPhZ {
  grid-area: statistics;
}

@media (min-width: 990px) {
  .HeroSection_flexboxSecond__TvEpy {
    justify-self: flex-end;
    width: 350px;
    height: 476px;
  }
}

@media (min-width: 1200px) {
  .HeroSection_flexboxSecond__TvEpy {
    width: 412px;
    height: 560px;
  }
}

@media (min-width: 1440px) {
  .HeroSection_container__aGPb9 {
    width: 1440px;
    grid-template-columns: minmax(450px, 500px) minmax(400px, 450px);
  }
}

@media (max-width: 990px) {
  .HeroSection_container__aGPb9 {
    flex-direction: column;
    gap: 0;
    padding: 16px 16px 32px 16px;
  }

  .HeroSection_flexboxFirst__9WDOT {
    flex-grow: 0;
    display: flex;
    flex-direction: column;
    padding: 0;
    gap: 8px;
  }

  .HeroSection_flexboxSecond__TvEpy {
    margin-bottom: 24px;
    width: 100%;
    height: auto;
    aspect-ratio: 343 / 167;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: unset;
  }

  .HeroSection_date__puM1q {
    margin-top: 0;
  }

  .HeroSection_title__KhsSj {
    font-size: 28px;
    line-height: 30.8px;
    width: 50vw;
    max-width: 190px;
  }

  .HeroSection_description__6I3mv {
    font-size: 16px;
    width: 70vw;
    max-width: 450px;
  }

  .HeroSection_title__KhsSj,
  .HeroSection_description__6I3mv {
    margin-bottom: 8px;
    word-break: break-word;
  }

  .HeroSection_pricebox__bZSoG {
    flex-direction: column;
    align-items: start;
    margin-bottom: 0.5rem;
  }
}

@media (min-width: 600px) and (max-width: 1340px) {
  .HeroSection_flexboxFirst__9WDOT::after {
    display: none;
  }
}

.HeroSectionCZ_containerWrapper__uUpci {
  display: flex;
  background-color: var(--section-background-color);
}

.HeroSectionCZ_container__I53kb {
}

.HeroSectionCZ_flexboxFirst__gAjvj {
  color: var(--text-body-color);
}

.HeroSectionCZ_flexboxSecond__NQTMO {
}

.HeroSectionCZ_flexboxFirst__gAjvj::after {
  content: ' ';
  position: absolute;
  background: url('/lp/assets/pl/lips.svg') no-repeat 0 0;
  background-size: contain;
}

.HeroSectionCZ_mainPrice__X2ybT {
  font-size: 2.25rem;
  line-height: 3rem;
  font-weight: 700;
}

.HeroSectionCZ_extra__E1GYq {
}

.HeroSectionCZ_mainPrice__X2ybT,
.HeroSectionCZ_extra__E1GYq {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.HeroSectionCZ_priceDescription__gltYC {
  font-size: 0.75rem;
  line-height: 1.125rem;
}

.HeroSectionCZ_date__l3rZl {
  font-size: 1.25rem;
  font-weight: 700;
  letter-spacing: 3%;
  max-width: 490px;
  margin-top: 16px;
}

.HeroSectionCZ_title__1__XJ {
  max-width: 490px;
}

.HeroSectionCZ_description__PRvO8 {
  max-width: 490px;
}

.HeroSectionCZ_button___TAbL {
  background-color: #e94d1d;
  margin-bottom: 0;
}

.HeroSectionCZ_button___TAbL:hover {
  background-color: color-mix(in hsl, #e94d1d 90%, white 10%) !important;
}

@media (min-width: 990px) {
  .HeroSectionCZ_container__I53kb {
    padding: 40px 80px;
    max-width: 1280px;
    grid-template-areas: 'text slider' 'statistics slider';
  }
}

@media (min-width: 1440px) {
  .HeroSectionCZ_flexboxFirst__gAjvj::after {
    display: block;
    aspect-ratio: 366 / 452;
    height: 452px;
    width: 366px;
    left: 430px;
    top: 75px;
  }

  .HeroSectionCZ_date__l3rZl {
    margin-bottom: -8px;
  }
}

@media (max-width: 990px) {
  .HeroSectionCZ_mainPrice__X2ybT {
    font-size: 1.5rem;
    line-height: 1.75rem;
  }

  .HeroSectionCZ_pricebox__CrFKd {
    align-items: flex-start;
    gap: 8px;
  }

  .HeroSectionCZ_flexboxSecond__NQTMO {
    margin: 32px 0;
  }
}

@media (max-width: 600px) {
  .HeroSectionCZ_flexboxFirst__gAjvj::after {
    display: block;
    transform: scaleX(-1);
    right: -50px;
    top: 100px;
    aspect-ratio: 133 / 164;
    width: 133px;
    height: 164px;
  }
}

.StatisticsSection_section__OKrUb {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
  row-gap: 2.5rem;
}

.StatisticsSection_item___7A_Q {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  align-items: center;
  text-align: center;
  flex-basis: calc(50% - 10px);
  max-width: calc(50% - 10px);
}

.StatisticsSection_item_title__jKg7D {
  font-size: 2.5rem;
  font-weight: 600;
  color: var(--accent-color)
}

.StatisticsSection_item_description__8lpv5 {
  line-height: 150%;
}

@media (min-width: 768px) {
  .StatisticsSection_section__OKrUb {
    gap: 0;
  }

  .StatisticsSection_item___7A_Q {
    width: 200px;
    flex-basis: auto;
    max-width: none;
  }
}

.StatisticsSectionRW_section__PexsZ {
  padding: 0;
  width: 100%;
  max-width: 470px;
  display: flex;
  justify-content: space-between;
  justify-self: flex-start;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  font-family: inherit;
  margin: 0;
}

.StatisticsSectionRW_item__c_r7H {
  width: 96px;
  gap: 4px;
  display: flex;
  align-items: flex-start;
}

.StatisticsSectionRW_item_title__w2dgA {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  display: flex;
  align-items: flex-start;
}

.StatisticsSectionRW_item_description__XKy_3 {
  font-size: 12px;
  line-height: 20px;
  text-align: left;
  color: #fff;
}

@media (max-width: 600px) {
  .StatisticsSectionRW_section__PexsZ {
    gap: 20px;
    flex-direction: column;
    width: 100%;
    align-items: stretch;
  }
  .StatisticsSectionRW_item__c_r7H {
    flex-direction: row;
    align-items: center;
    max-width: unset;
    width: 100%;
  }
  .StatisticsSectionRW_item_title__w2dgA {
    font-size: 18px;
    width: 64px;
    justify-content: center;
  }
  .StatisticsSectionRW_item_description__XKy_3 {
    flex: 1 1;
  }
}

.StatisticsSectionCZ_item_title__AXVqn {
  font-weight: 400;
}

.StatisticsSectionCZ_item_description__odEIh {
  font-family: var(--font-roboto);
}
.LogosSection_container__o0UxL {
  display: flex;
  flex-direction: column;
  margin: 4rem auto;
}

.LogosSection_title__L_JQL {
  text-align: center;
  margin-bottom: 3rem;
}

.LogosSection_logoSectionContainer__4q1ts {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 3rem;
  text-align: center;
}

.LogosSection_logos__x_JXr {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 1rem;
}

.LogosSection_logoImage__oT8PX {
  -o-object-fit: contain;
     object-fit: contain;
  width: 170px;
  height: 122px;
}

.LogosSection_sectionTitle__YvmS_ {
  margin-bottom: 2rem;
}


.LogosSectionRW_container__1pjKv {
  margin: 7.5rem auto 6.25rem auto;
}

.LogosSectionRW_title__AgWqI {
  font-size: 40px;
}

.LogosSectionRW_sectionTitle__UeKmF {
  line-height: 18px;
}

.LogosSectionRW_logoSectionContainer__kaFWO {
}

@media (max-width: 600px) {
  .LogosSectionRW_partners__sC6ZF {
    display: none;
  }
  .LogosSectionRW_container__1pjKv {
    margin: 4rem 0 5rem 0;
  }
  .LogosSectionRW_title__AgWqI {
    font-size: 28px;
    line-height: 30.8px;
    letter-spacing: -0.56px;
    margin-bottom: 2rem;
  }
  .LogosSectionRW_logoSectionContainer__kaFWO {
    gap: 0;
    margin-bottom: 2rem;
  }
}

.HeroSectionRWPL_container__xxyA3 {
}

.HeroSectionRWPL_flexboxFirst__BS5lt {
}

.HeroSectionRWPL_flexboxSecond__pBV2Z {
}

.HeroSectionRWPL_flexboxFirst__BS5lt::after {
  content: ' ';
  position: absolute;
  background: url('/lp/assets/pl/hand.svg') no-repeat 0 0;
  background-size: contain;
}

.HeroSectionRWPL_pricebox__Xu1ry {
  max-width: 264px;
}

.HeroSectionRWPL_pricebox__Xu1ry > h2 {
  color: #fff;
  font-size: 12px;
  line-height: 18px;
}

.HeroSectionRWPL_mainPrice__5Aah8 {
  font-weight: 800;
  color: var(--accent-color);
}

.HeroSectionRWPL_extra__tg9Qf {
}

h2.HeroSectionRWPL_mainPrice__5Aah8 {
  color: var(--accent-color);
  font-weight: 700;
  font-size: 36px;
  line-height: 48px;
}

.HeroSectionRWPL_mainPrice__5Aah8,
.HeroSectionRWPL_extra__tg9Qf {
  font-size: 1.5rem;
  line-height: 1.75rem;
}

.HeroSectionRWPL_date__dkSXf {
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 3%;
  margin-top: 32px;
}

.HeroSectionRWPL_title__obdu6 {
  width: 70vw;
  max-width: 548px;
  color: #fff;
}

.HeroSectionRWPL_description__1ZqhI {
  max-width: 459px;
  color: #fff;
  width: 60vw;
}

.HeroSectionRWPL_button__Mja49 {
}

.HeroSectionRWPL_statistics__9ysVa {
}

@media (min-width: 990px) {
  .HeroSectionRWPL_container__xxyA3 {
    padding: 40px 80px 72px 80px;
    max-width: 1280px;
    grid-template-areas: 'text slider' 'statistics slider';
  }
}

@media (min-width: 1340px) {
  .HeroSectionRWPL_flexboxFirst__BS5lt::after {
    display: block;
    aspect-ratio: 525 / 643;
    height: 643px;
    width: 525px;
    left: 420px;
    top: 32px;
  }
}

@media (max-width: 990px) {
  h2.HeroSectionRWPL_mainPrice__5Aah8 {
    font-size: 24px;
    line-height: 28px;
  }

  .HeroSectionRWPL_date__dkSXf {
    margin-top: 0;
  }

  .HeroSectionRWPL_title__obdu6 {
    font-size: 28px;
    line-height: 30.8px;
    width: 50vw;
    max-width: 190px;
  }

  .HeroSectionRWPL_description__1ZqhI {
    font-size: 15px;
    line-height: 18px;
    width: -moz-fit-content;
    width: fit-content;
    max-width: 245px;
  }

  .HeroSectionRWPL_title__obdu6,
  .HeroSectionRWPL_description__1ZqhI {
    margin-bottom: 16px;
    word-break: break-word;
  }

  .HeroSectionRWPL_pricebox__Xu1ry {
    flex-direction: row;
    align-items: flex-end;
    gap: 7px;
  }

  .HeroSectionRWPL_flexboxFirst__BS5lt::after {
    display: block;
    width: 147px;
    height: 181px;
    left: 66%;
    top: -22px;
  }
}

@media (max-width: 768px) {
  .HeroSectionRWPL_button__Mja49 {
    width: 100%;
  }
}

.AdultModalRW_rejectButton__HcnMI {
  color: var(--text-accent-color);
}

.alice-carousel .animated {
  animation-fill-mode: both;
}

.alice-carousel .animated-out {
  z-index: 1;
}

.alice-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    visibility: hidden;
  }
}
.alice-carousel {
  position: relative;
  width: 100%;
  margin: auto;
  direction: ltr;
}

.alice-carousel__wrapper {
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
  box-sizing: border-box;
  width: 100%;
  height: auto;
}

.alice-carousel__stage {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  transform-style: flat;
  -webkit-transform-style: flat;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
}
.alice-carousel__stage-item {
  position: relative;
  display: inline-block;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  vertical-align: top;
  white-space: normal;
  line-height: 0;
}
.alice-carousel__stage-item * {
  line-height: initial;
}
.alice-carousel__stage-item.__hidden {
  opacity: 0;
  overflow: hidden;
}

.alice-carousel__prev-btn,
.alice-carousel__next-btn {
  display: inline-block;
  box-sizing: border-box;
  width: 50%;
  padding: 10px 5px;
}
.alice-carousel__prev-btn [data-area]::after,
.alice-carousel__next-btn [data-area]::after {
  position: relative;
  content: attr(data-area);
  text-transform: capitalize;
}

.alice-carousel__prev-btn {
  text-align: right;
}

.alice-carousel__next-btn {
  text-align: left;
}

.alice-carousel__prev-btn-item,
.alice-carousel__next-btn-item {
  display: inline-block;
  cursor: pointer;
  padding: 5px;
  margin: 0;
  color: #465798;
}
.alice-carousel__prev-btn-item:hover,
.alice-carousel__next-btn-item:hover {
  color: darkred;
}
.alice-carousel__prev-btn-item.__inactive,
.alice-carousel__next-btn-item.__inactive {
  opacity: 0.4;
  pointer-events: none;
}

.alice-carousel__play-btn {
  position: absolute;
  top: 30px;
  left: 20px;
  display: inline-block;
}
.alice-carousel__play-btn:hover {
  cursor: pointer;
}
.alice-carousel__play-btn-wrapper {
  position: relative;
  width: 32px;
  height: 32px;
  padding: 10px;
  border-radius: 50%;
  background-color: #fff;
}

.alice-carousel__play-btn-item {
  position: absolute;
  width: 32px;
  height: 32px;
  cursor: pointer;
  border: 0;
  outline: none;
  background: transparent;
}
.alice-carousel__play-btn-item::before, .alice-carousel__play-btn-item::after {
  position: absolute;
  pointer-events: none;
  display: block;
  width: 0;
  height: 0;
  content: "";
  transition: all 0.4s linear;
  border-width: 8px 0 8px 15px;
  border-style: solid;
  border-color: transparent;
  border-left-color: #465798;
}
.alice-carousel__play-btn-item::before {
  left: 5px;
  height: 14px;
}
.alice-carousel__play-btn-item::after {
  top: 7px;
  left: 18px;
}
.alice-carousel__play-btn-item.__pause::before, .alice-carousel__play-btn-item.__pause::after {
  height: 30px;
  border-width: 0 0 0 10px;
}
.alice-carousel__play-btn-item.__pause::after {
  top: 0;
  left: 18px;
}

.alice-carousel__dots {
  margin: 30px 3px 5px;
  padding: 0;
  list-style: none;
  text-align: center;
}
.alice-carousel__dots > li {
  display: inline-block;
}
.alice-carousel__dots-item:not(.__custom) {
  width: 8px;
  height: 8px;
  cursor: pointer;
  border-radius: 50%;
  background-color: #e0e4fb;
}
.alice-carousel__dots-item:not(.__custom):not(:last-child) {
  margin-right: 20px;
}
.alice-carousel__dots-item:not(.__custom):hover, .alice-carousel__dots-item:not(.__custom).__active {
  background-color: #6e7ebc;
}

.alice-carousel__slide-info {
  position: absolute;
  top: 20px;
  right: 20px;
  display: inline-block;
  padding: 5px 10px;
  color: #465798;
  border-radius: 5px;
  background-color: rgba(224, 228, 251, 0.6);
}
.alice-carousel__slide-info-item {
  vertical-align: middle;
  line-height: 0;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_91160d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/lp/_next/static/media/fe0777f1195381cb-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_91160d';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/lp/_next/static/media/eafabf029ad39a43-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* latin-ext */
@font-face {
  font-family: '__Poppins_91160d';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/lp/_next/static/media/a1386beebedccca4-s.p.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: '__Poppins_91160d';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/lp/_next/static/media/b957ea75a84b6ea7-s.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: '__Poppins_Fallback_91160d';src: local("Arial");ascent-override: 92.33%;descent-override: 30.78%;line-gap-override: 8.79%;size-adjust: 113.73%
}.__className_91160d {font-family: '__Poppins_91160d', '__Poppins_Fallback_91160d';font-style: normal
}.__variable_91160d {--font-poppins: '__Poppins_91160d', '__Poppins_Fallback_91160d'
}

